@font-face {
  font-family: "bannerFont";
  src: local("JejuHallasan"), url(./fonts/JejuHallasan.woff) format("woff");
}

@font-face {
  font-family: "Microgramma";
  src: local("Microgramma"), url(./fonts/Microgramma.otf) format("opentype");
}
/* slick dots  */

.Latest_slick .slick-dots li.slick-active button:before {
  display: block;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #01ffff !important;
  text-indent: -9999px;
}

.Latest_slick .slick-dots li:not(.slick-active) button:before {
  display: block;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #01ffff99 !important;
  text-indent: -9999px;
}
/* stepper  */
.css-1vyamtt-MuiStepLabel-labelContainer {
  color: white !important;
}
/* .css-rrql4a-MuiSvgIcon-root-MuiStepIcon-root {
  color: white !important;
} */

/* dialog backgroudn  */
/* .css-1sgyiqv-MuiPaper-root-MuiDialog-paper {
  background-color: #1D2326 !important  ;
} */
